* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  /*font-family: 'Roboto', sans-serif;*/
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: linear-gradient(to bottom, #0A0813, #0A0813);*/
  background: #0A0813;
  /* 091E2C */
  color: #fff;
}
b {
  font-weight: 700;
}
button {
  font-family: 'Roboto', sans-serif;
}
a {
  text-decoration: none;
}
.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
.react-responsive-modal-modal {
  width: 100%;
  height: 100Vh;
  margin: 0 !important;
  backdrop-filter: blur(10px);
  background: rgba(0,0,0,0.2) !important;
  padding: 0 !important;
  max-width: 100% !important;
}
.react-responsive-modal-closeButton {
  outline-style: none;
}
h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 1024px) {
  h2 {
    font-size: 30px;
  }
}
#animation {
  position: fixed;
  top: 100px;
  z-index: -1;
  height: 100vh;
}

#animation img {
  object-fit: cover;
  display: none;
}

#animation.active img {
  display: block;
}

#animation.active canvas {
  display: none;
}

canvas {
}
/* #root > div > div {
  backdrop-filter: blur(60px);
} */

.h1 {
  font-size: 42px;
  font-weight: bolder;
}

.h2 {
  font-size: 21px;
  font-weight: lighter;
}

.h3 {
  font-size: 18px;
}

.font-bold {
  font-weight: 600;
}

.text {
  font-size: 14px;
  font-weight: lighter;

  @media (max-width: 800px) {
    font-size: 12px;
  }
}

.text-center {
  text-align: center;
}

.center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-block {
  display: inline;
  @media (max-width: 800px) {
    display: block;
  }
}

.option-item {
  padding: 0 30px;
  @media (max-width: 800px) {
    padding: 0 15px;
  }
}

.option-item div {
  font-size: 21px;
  height: 30px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    font-size: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-bottom: 30px;
  font-size: 14px;
  justify-content: space-between;
  z-index: 100000000000;
  position: relative;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}

.footer .link {
  color: rgba(255, 255, 255, 0.86);
  margin-right: 20px;
}

.footer-subtitle {
  color: #6a708b;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: start;
}

.items-center {
  align-items: center;
}

.self-start {
  align-self: start;
}

.self-end {
  align-self: end;
}

.option-1 {
  align-self: flex-start;
  @media (min-width: 1000px) {
    align-self: start;
  }
}

.option-1>div {
  @media (max-width: 1000px) {
    flex-direction: row-reverse;
  }
}

.option-1>div>div {
  text-align: right;
  @media (max-width: 1000px) {
    text-align: left;
    margin-left: 5px;
  }
}

.option-2 {
  padding: 0 10px;
  @media (min-width: 1000px) {
    text-align: center;
  }
}

.option-3 {
  @media (min-width: 1000px) {
    align-self: end;
  }
}

.justify-center {
  justify-content: center;
}

.ml-auto {
  margin-left: auto;
}

.gap-4 {
  gap: 20px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.options-icons {
  width: 450px;
  font-size: 16px;
  @media (max-width: 1024px) {
    font-size: 14px;
    width: 380px;
    padding: 0 20px 0 30%;
    transform: translateX(-20px);
  }
}

.options-icons-left {
  margin-left: 33px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}
